@import '../../styles/main.scss';

.event {    
    display: flex;
    padding: 1rem 0;
    width: 100%;
    position: relative;

    @include breakpoint-down(mobile) {
        flex-direction: column;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        background-color: $c-white;

        @include breakpoint-down(mobile) {
            left: 0;
            transform: translateX(0);
        }
    }

    &:after {
        top: 2.25rem;
        width: .4rem;
        height: .4rem;
        border-radius: 50%;
        background-color: $c-secondary;
        @include breakpoint-down(mobile) {
            transform: translateX(-30%);
        }
    }

    .left,
    .right {
        width: 50%;
        @include breakpoint-down(mobile) {
            width: 100%;
        }
    }

    .left {
        position: relative;
        text-align: right;
        padding-right: 3rem;

        @include breakpoint-down(mobile) {
            padding-right: 0;
            padding-left: 1rem;
            margin-bottom: 1rem;
            text-align: left;
        }
        
        h4 {
            text-transform: uppercase;
            margin-bottom: .35rem;
        }

        span {
            font-size: .75rem;
            color: $c-grey-light;
        }
    }

    .right {
        text-align: left;
        padding-left: 3rem;

        @include breakpoint-down(mobile) {
            padding-left: 1rem;
        }
    }
}