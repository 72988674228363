@import '../../styles/main.scss';

.close {
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    border: none;
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;
    background-color: #999;
    cursor: pointer;
    transition: all $t-duration ease-in-out;

    &:hover {
        background-color: rgb(200, 195, 195);
    }

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: .75rem;
        height: 2px;
        content: '';
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}