@import '../../styles/main.scss';
@import '../page';

.about {
    flex-direction: column;
    height: 100%;
    position: relative;

    img {
        margin: 0 auto;
    }
}

.background {
    background-image: url('../../../public/Images/about.png');
}

.picture {
    max-width: 25.75rem;
}

.body {
    display: flex;
    flex-direction: column;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: start;


}

.organization {
    line-height: 1.6;
    h6 {
        margin-bottom: .75rem;
        font-style: italic;
    }

    ol {
        list-style-type: number;
    }

    ol > li > ol {
        margin-top: 1rem;
        padding-left: 1rem;
    }

    li + li {
        margin-top: 1rem;
    }
    
    & > li + li {
        margin-top: 2.5rem;
    }
    
}

.stats {
    li {
        display: flex;
    }
    
    li + li {
        margin-top: 2rem;
    }

    span:first-child {
        font-weight: 500;
        display: block;
        width: 8rem;
    }
    
}