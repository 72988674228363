@import '../styles/main.scss';

.page {
    padding: 2rem;
    line-height: 1;

    @include breakpoint-down(mobile) {
        padding: 1.5rem;
    }
}

.background {
    position: absolute;
    inset: 0;
    z-index: -1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.heading {
    position: relative;
    padding-bottom: 1.25rem;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 10%;
        right: 0;
        height: 1px;
        background-color: $c-white;
    }

    span {
        font-size: .6rem;
        color: $c-grey-light;
    }
}

.subheading {
    text-transform: none;
    
    &:after {
        left: 15%;
    }
}


.row {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;

    @include breakpoint-down(mobile) {
        flex-direction: column;
    }
}

.reverse {
    @include breakpoint-down(mobile) {
        flex-direction: column-reverse;
    }
}