@import '../../styles/main.scss';

.medalsWrapper {
    padding: 1rem;
    background-color: #1C1D22;
}

.medals {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    padding: .5rem;
    border-radius: .5rem;
    background-image: url('../../../public/Images/background-gold.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: calc(100% / 5 - (.5rem * (5 - 1))/5);
        padding: 1rem;
        border-radius: .5rem;
        z-index: 31;
        background-image: url('../../../public/Images/background-gold-brown.png');
        background-position: center 100%;
        background-repeat: no-repeat;
        background-size: cover;

        box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.5), 0 0 5px rgba(0, 0, 0, 0.5);
        &:hover {
            cursor: pointer;
            z-index: 33;
            img {
                filter: drop-shadow(0px 0px 10px rgba(254, 219, 55, .5));
            }

            .info {
                opacity: 1;
                z-index: 34;
            }
        }
    }

    

    img {
        max-height: 6rem;
        max-width: 6rem;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
        transition: all $t-duration ease-in-out;
    }

    .info {
        pointer-events: none;
        position: absolute;
        top: calc(100% + .5rem);
        left: 50%;
        width: 20rem;
        transform: translate(-50%, 0);
        padding: .75rem;
        border-radius: .5rem;
        box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5), 0 0 30px rgba(0, 0, 0, 0.5);
        // background: #C19A6B;
        // background: -webkit-linear-gradient(top, #C19A6B 0%, #B28B5C 100%);
        // background: -moz-linear-gradient(top, #C19A6B 0%, #B28B5C 100%);
        // background: -o-linear-gradient(top, #C19A6B 0%, #B28B5C 100%);
        // background: -ms-linear-gradient(top, #C19A6B 0%, #B28B5C 100%);
        // box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.5), 0 0 30px rgba(0, 0, 0, 0.5);
        background-image: url('../../../public/Images/background-marble.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        

        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all $t-duration ease-in-out;
        z-index: 33;

        h3 {
            background: radial-gradient(ellipse farthest-corner at right bottom, #d8e0a8 0%, #fff5b7 20%, #fff5b7 50%, #fff5b7 100%),
            radial-gradient(ellipse farthest-corner at left top, #2d2d2d 0%, #2d2d2d 37.5%, #7d7d7d 75%, #b2b2b2 92%, #f2f2f2 100%);
            text-shadow: 4px 4px 20px rgba(255, 215, 0, 0.5);
            font-weight: 900;
        }

        p {
            padding: 0;
            line-height: 1.35;
            font-weight: 500;
            font-size: 1.15rem;
            background: -webkit-linear-gradient(300deg, #431f16  , #a14107);
        }

        h3, p {
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}