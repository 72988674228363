@import '../../styles/main.scss';

.legionary {    
    position: relative;
    width: calc(100% / 3 - (2rem * (3 - 1))/3);
    cursor: pointer;
    color: #532f04;
    @include breakpoint-down(mobile) {
        width: calc(100% / 2 - (2rem * (2 - 1))/2);
    }
    

    &:after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: opacity $t-duration ease-in-out;
        z-index: 3;
    }
    .imageWrapper {
        position: relative; 
        padding: .5rem;
        padding-bottom: 0;
        z-index: 2;
        @include breakpoint-down(mobile) {
            padding: .25rem;
        }
    }

    .border {
        position: absolute;
        inset: 0;
    }

    img {
        width: 100%;
    }

    .about {
        background-image: url('../../../public/Images/background-gold.png');
    background-position: 100% center;
    background-repeat: no-repeat;
    background-size: cover;
        font-size: 1.3rem;
        position: relative;
        @include flexCenter;
        font-weight: 600;
        z-index: 1;
        padding: .3rem 0 1.5rem 0;
        @include breakpoint-down(mobile) {
            padding: .3rem 0 1rem 0;
        }

        p {
            font-weight: 700;
        }

        .border {
            left: 0;
            right: auto;
            top: auto;
           bottom: 0;
        }
    }
}
.legionary:hover {
    &:after {
        opacity: 1;
    }
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .legionary {
        cursor: auto;
        width: 100%;
        max-width: 32rem;
        @include breakpoint-down(mobile) {

            transform: scale(1.5);
        }
    }
    img {
        width: 100%;
    }

    .legionary:hover {
        &:after {
            opacity: 0;
        }
    }

    .about {
       display: flex;
         flex-direction: column;
         align-items: center;
         text-align: left;
        padding: .6rem 1rem 3rem;
       @include breakpoint-down(mobile) {
           padding: 1rem 1rem 1.4rem;
       }
    }
}

.name {
    font-weight: 900;
}