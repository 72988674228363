$font-size-base: 1rem;
$font-weight-base: 300;
$line-height-base: 1.2;

$ff-head: 'Roboto Slab', serif;
$ff-main: 'Roboto Slab', serif;

$t-duration: 0.4s;

$c-black: #000;
$c-grey-black: #222222;
$c-grey-dark: #272727;
$c-grey: #333333;
$c-grey-text: #444444;
$c-grey-2: #3B414C;
$c-grey-light-2: #696666;
$c-grey-light: #a3a3a3;
$c-white: #fff;

$c-main: #540202;

$c-secondary: #FFD700;

$c-green: #009c00;
$c-green-light: rgba($color: #009c00, $alpha: .5);
$c-yellow: #ffff02;
$c-yellow-light: rgba($color: #ffff02, $alpha: .5);
$c-red: #bc0000;
$c-red-light: rgba($color: #bc0000, $alpha: .5);

$responsive-ios: 812px;

$breakpoints: (
  mobile-small: 374px,
  mobile-medium: 575px,
  mobile: 767px,
  admin-bar: 782px,
  tablet: 1023px,
  desktop-small: 1199px,
  desktop-medium: 1399px
);