@import '../../styles/main.scss';
@import '../page';

.people {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    position: relative;

    &:after {
        content: '';
        display: block;
        position: sticky;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0px 25px 45px 40px rgba(0,0,0,1);
        z-index: 30;
    }
}

.background {
    background-image: url('../../../public/Images/page-people.jpg');
}

.body {
    position: relative;
}

.menu {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 0 auto 2rem;
    font-weight: 400;
    position: relative;
    @include breakpoint-down(mobile) {
        gap: 1rem;
        flex-wrap: wrap;
    }

    img {
        max-height: 12rem;
        @include breakpoint-down(mobile) {
            max-height: 8rem;
        }
    }

    li {
        cursor: pointer;
        transition: all $t-duration;
        img {
            transition: all $t-duration;
        }
        top: 0;

        &:hover {
            img {filter: drop-shadow(0px 0px 30px #540202);}
        }
    }

    li.active {
        position: relative;
        top: -1rem;
        img {filter: drop-shadow(0px 0px 30px #540202);}
    }
}

.legionaries {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;
    padding-bottom: 2.5rem;
    @include scroll;
    @include breakpoint-down(mobile) {
        justify-content: center;
        gap: 1rem;
    }
   
}

.quote {
    margin: 0 -2rem;
    padding: 4rem 8rem;
    text-align: center;
    background-color: #fefefe;
    color: $c-grey-2;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 500;
    margin-bottom: -2rem;
}

.banner {
    max-height: 200px;
}