@import '../../styles/main.scss';

.backdrop {
    position: fixed;
    inset: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 31;
  }

.modal {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 31;
  }

.close {
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    z-index: 21;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0rem;
      left: 50%;
      width: .25rem;
      height: 3rem;
      background-color: $c-grey;
      transform: translate(-50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, 0) rotate(-45deg);
    }
}

.close:hover {
    &:before,
    &:after {
      background-color: $c-grey-light;
    }
  }
