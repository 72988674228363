@import '../../styles/main.scss';

.burger {
  display: flex;
  width: 2.5rem;
  height: 2.1rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 0.3rem;
    width: 100%;
    background: $c-white;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }
  
  span:nth-child(2), span:nth-child(3) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  span:nth-child(4) {
    bottom: 0;
  }
}

.burger.open {
  span:nth-child(1) {
    top: 50%;
    transform: translateY(-50%);
    width: 0%;
    left: 50%;
  }

  span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  span:nth-child(4) {
    top: 50%;
    transform: translateY(-50%);
    width: 0%;
    left: 50%;
  }
}