@import '../../styles/main.scss';

.box {
    display: flex;
    flex-direction: column;
    min-width: 17rem;
    padding: .5rem;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #000;
    font-size: 1rem;
    font-weight: 400;
    color: $c-black;
    padding: 0 15px 15px 15px;


    .btn {
        background-color: #868585;
        margin: 1rem auto 0 auto;
        padding: .5rem .5rem;
        width: 150px;

        &:hover {
            background-color: #03e3fc;
            color: $c-white;
        }
    }
}

.filtersBox {
    max-width: 25rem;
    margin-top: 2rem;

    .filter {
        display: 'inline-block';
    }

    .flagFilter {
        height: 24px;
    }
}

.customTooltipContainer {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.customTooltipText {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.customTooltipContainer:hover .customTooltipText {
    visibility: visible;
}

.flagInList {
    height: 40px;
}

.horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #000000;
    margin-top: 10px;
    margin-bottom: 10px;
}