@import '../../styles/main.scss';

.leaflet-container {
  width: 50px;
  height: 90px;
  z-index: 1000;
}

.mapMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -8px;

  h3 {
    cursor: pointer;
    margin: 0;
  }

}

.control {
  max-height: 98vh;
  @include scroll;
}

.customTooltipContainer {
  position: relative;
  display: inline-block;
  cursor: pointer;
}