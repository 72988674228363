@import '../../styles/main.scss';
@import '../page';

.invite  {
    p {
        margin-bottom: 1.5rem;
        font-size: 2rem;
        text-align: center;
    }

    a {
        color: red;

        &:hover {
            text-decoration: underline;
        }
    }

    .row {
        justify-content: center;
    }
}



.background {
    background-image: url('../../../public/Images/page-invite.png');
    background-position: center;
}