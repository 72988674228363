html {
  scroll-behavior: smooth;
}

body {
  min-width: 320px;
  background-color: #f5f5f5;
  font-family: $ff-main;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: #000;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 2.5rem;
  background-image: url('../../public/Images/background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint-down(mobile) {
    padding: 0;
  }
}

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 73.125rem;
  box-shadow: 0px 0px 20px 0px rgba(163,163,163,0.75);
  z-index: 3;

  @include breakpoint-down(mobile) {
    flex-direction: column;
  }
}

.pages {
  width: 100%;
  height: 100%;
  color: $c-white;

  @include breakpoint-down(mobile) {
    padding-top: 4rem;
  }
 
}

.page {
  @include scroll;
  height: 100%;
  background-color: rgba(20, 19, 19, 0.95);
}

a {
  color: inherit;
  text-decoration: none;
}

.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  font-family: $ff-head;
  font-weight: 500;
  letter-spacing: 0.05em;
  word-spacing: 0.1em;
}

.h1,
h1 {
  font-size: 6rem;
  text-transform: uppercase;
}

.h2,
h2 {
  font-size: 2.25rem;
  margin-bottom: 2rem;
}

.h3,
h3 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.h4,
h4 {
  font-size: 1.225rem;
}

.h5,
h5 {
  font-size: 1.125rem;
}

.h6,
h6 {
  font-size: 1rem;
}

p {
  line-height: 1.7;
  word-spacing: .1em;
}

ul,
ol {
  list-style: none;
}