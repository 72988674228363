@import '../../styles/main.scss';
@import '../page';

.media {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: stretch;
}


.background {
    background-image: url('../../../public/Images/page-media.jpg');
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
