@import '../../../styles/main.scss';

.marker {
    background-color: red;
    width: 20px;
    height: 20px;
}

.popup {
    text-align: center;
    margin: -10px;
}

.tooltip {
    padding: .15rem .25rem;
    font-size: .85rem;
    font-weight: 400;
    color: $c-black;
    z-index: 1110;
}

%armyBase {
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 11120;
    transition: background-color $t-duration;
}

.army {
    @extend %armyBase;

    &:hover {
        background-color: rgba(255, 220, 94, 0.4);
    }
}

.selectedArmy {
    @extend %armyBase;
    background-color: rgba(255, 220, 94, 0.6);
}

.fontSize {
    font-size: 10px;
}

.army+.army {
    margin-top: .25rem;
}

.scout {
    border-top: 1px solid #000;
}

.flag {
    height: 1.5rem;
    margin-right: .25rem;
    transition: all $t-duration;
}

.bigFlag {
    height: 1.75rem;
}

.bigIcon {
    font-size: 1.75rem;
}

.walls {
    font-size: 1rem;
    margin-right: .25rem;
}

.recruitment {
    font-size: 1rem;
    margin-right: .25rem;
}

.friend {
    color: $c-green;
}

.friendForEdit {
    color: $c-green-light;
}

.neutral {
    color: $c-black;
}

.neutralForEdit {
    color: $c-grey-light;
}

.enemy {
    color: $c-red;
}

.enemyForEdit {
    color: $c-red-light;
}

.unsure {
    color: $c-red;
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 1;
    margin: 0 .5rem;
    vertical-align: middle;
}

.unsureDate {
    font-size: 1rem;
}