@import '../../styles/main.scss';

.nodeBox {
    min-width: 17rem;
    max-height: 100%;
    padding: .5rem;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #000;
    font-size: 1rem;
    font-weight: 400;
    color: $c-black;

    li {
        display: flex;
        flex-direction: column;
    }

    .prop {
        color: $c-main;
    }
}

.armies {
    li {
        cursor: pointer;
        &:hover {
            color: $c-main;
        }
    }
}

.armyModal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 17rem;
    height: 50rem;
    background-color: red;
}

.btn {
    background-color: #868585;
    margin: 1rem auto 1rem 0;
    padding: .5rem .5rem;
    font-size: 0.9rem;
    width: 150px;

    &:hover {
        background-color: #03e3fc;
        color: $c-white;
    }
}