@import '../../../styles/main.scss';

.form {
    @include scroll;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    min-width: 20rem;
    min-height: 20rem;
    max-height: 98vh;
    padding: .5rem;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #000;
    font-size: 1rem;
    font-weight: 400;
    color: $c-black;

    form {
        display: flex;
        flex-direction: column;
        gap: .75rem;
    }

    input, textarea {
        padding: .25rem .5rem;
        font-size: 1rem;
        font-weight: 400;
        color: $c-black;
        border: 2px solid transparent;
        transition: all $t-duration ease-in-out;

        &:focus {
            outline: none;
            border: 2px solid #03e3fc;
        }
    }

    textarea {
        resize: none;
        height: 8rem;
    }

    .btn {
        background-color: #868585;
        color: $c-white;
        margin: 0 auto;
        padding: .5rem .5rem;

        &:hover {
            background-color: #03e3fc;
        }
    }
    .delete {
        background-color: #b10202;

        &:hover {
            background-color: #ff0000;
        }
    }
    
    .group {
        display: flex;
        flex-direction: column;
        gap: .25rem;
    }
}