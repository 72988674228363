@import '../../styles/main.scss';
@import '../page';

.history {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: stretch;
    overflow-X: hidden;
}

.background {
    background-image: url('../../../public/Images/page-history.jpg');
}

.body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.timeline {
    @include flexCenter;
    flex-direction: column;

    @include breakpoint-down(mobile) {
        align-items: start;
    }

    .start {
        @include flexCenter;
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 1rem;
        border-radius: 50%;
        background-color: $c-grey-2;
        color: $c-white;
        position: relative;

        @include breakpoint-down(mobile) {
            transform: translateX(-47%);
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100%);
            width: 2px;
            height: 1rem;
            background-color: $c-white;
        }
    }

    ul {
        width: 100%;
    }
}

.quote {
    margin: 0 -2rem;
    padding: 4rem 8rem;
    text-align: center;
    background-color: #fefefe;
    color: $c-grey-2;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 500;
    margin-bottom: -2rem;

    @include breakpoint-down(mobile) {
        padding: 2rem;
    }
}