@import '../../styles/main.scss';
@import '../page';

.home {
    @include flexCenter;
    flex-direction: column;
    height: 100%;
    color: $c-grey;
    position: relative;
}

.banner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.logo {
    filter: drop-shadow(0px 0px 25px #000);
    max-width: 100%;
}