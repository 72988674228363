@import '../../styles/main.scss';

.form {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    min-width: 20rem;
    min-height: 5rem;
    padding: .5rem;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #000;
    font-size: 1rem;
    font-weight: 400;
    color: $c-black;

    select {
        min-width: 10rem;
        padding: 2px 4px;
    }

    .btn {
        background-color: #868585;
        color: $c-white;
        margin: 0 auto;
        padding: 0.2rem;
        min-width: 4rem;
        max-width: 4rem;
        cursor: pointer;

        &:hover {
            background-color: #03e3fc;
        }

        &:disabled {
            background-color: #ccc; /* Change the background color for disabled state */
            color: #666;
            cursor: default; /* Change the text color for disabled state */
            /* Any other styles for the disabled state */
        }
    }

    .group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
}