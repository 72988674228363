.Map {
  width: 100%;
  height: 100%;
}

.leaflet-container {
  width: 100vw;
  height: 100vh;
  margin-right: 0;
}

.box {
	position: absolute;
	right: 2px;
	top: 2px;
  width: 250px;
  height: 70vh;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  color: black;
}