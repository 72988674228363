@import '../../../styles/main.scss';

.flag {
    img {
        filter: drop-shadow(0px 0px 27px #000);
    }
}

.leaflet-tooltip.custom-tooltip {
    background: rgba(0, 0, 0, 0.8);
    /* Optional: Customize the background */
    color: white;
    /* Optional: Customize the text */
    border-radius: 5px;
    /* Optional: Round corners */
    padding: 5px;
    /* Optional: Adjust padding */
}

.leaflet-tooltip.custom-tooltip::before,
.leaflet-tooltip.custom-tooltip::after {
    display: none !important;
    /* Forcefully remove the arrow */
}