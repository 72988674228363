@import '../../styles/main.scss';


.tree {
  text-align: center;
  @include scroll;
  overflow: auto;
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
  inset: 0;  


  ul {
    display: flex;
    position: relative;
    padding-top: 1.25rem;
    transition: $t-duration;
  }

  li {
    position: relative;
    display: inline-table;
    text-align: center;
    padding: 1rem;
    transition: $t-duration;
  }

  li:before, li:after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid $c-grey-light;
    width: 50%;
    height: .9rem;
  }

  li:after {
    right: auto;
    left: 50%;
    border-left: 2px solid $c-grey-light;
  }

  li:only-child:before, li:only-child:after {
    display: none;
  }

  li:only-child {
    padding-top: 0;
  }

  li:first-child:before, li:last-child:after {
    border: 0 none;
  }

  li:last-child:before {
    border-right: 2px solid $c-grey-light;
  }
  
  ul ul:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px solid $c-grey-light;
    width: 0;
    height: 1.25rem;
  }

  .element {
    @include flexCenter;
    position: relative;
    background-color: $c-grey;
    color: $c-white;
    width: 10rem;
    height: 4rem;
    display: inline-grid;
    border-radius: 5px;
    transition: $t-duration;

    &:hover, &:hover+ul li .element {
      opacity: .7;
      cursor: pointer;
    }
  }

  .border {
    position: absolute;
    inset: 0;
    width: 100%;
  }

  .vertical {
    flex-direction: column;

    li {
      &:before, &:after {
        border: 0 none;
      }
    }
    
    li:nth-child(1) {
      &:before {
        border-top: 2px solid $c-grey-light;
        width: calc(50% + 1.3px);
      }
    }
  
    li, li:only-child {
      display: flex;
      justify-content: start;
      padding: .7rem 0 0.7rem 1rem;
      border-left: 2px solid $c-grey-light;
  
      &:before, &:after {
        border: 0 none;
      }
  
      &:before:last-child {
        border: 0 none;
      }
  
      &:before {
        height: 50%;
      }
  
      &:after {
        left: -1px;
        height: 50%;
        width: calc(1rem + 1px);
        border-bottom: 2px solid $c-grey-light;
      }
    }
  
    li:last-child {
  
      border: none;
      &:before {
        border-right: 0;
        width: calc(1rem - 2px);
        left: 0;
        border-bottom: 2px solid $c-grey-light;
        border-left: 2px solid $c-grey-light;
      }
  
      &:after {
        display: none;
      }
    }
  
    li:only-child {
      position: relative;
      border: none;
      
       &:before {
         content: '';
         display: block;
         position: absolute;
         width: auto;
         width: 1rem;
         height: 50%;
         border-bottom: 2px solid $c-grey-light;
       }
  
       &:after {
        content: '';
        display: block;
        border-left: 2px solid $c-grey-light;
        border-top: 2px solid $c-grey-light;
        border-bottom: none;
         width: 50%;
         height: 50%;
         left: 0;
         top: 0;
         transform: translate(0, 0);
         background-color: transparent;
       }
       
     }
  }
}

.legatus {
  .element {
    background-color: #daca12;
  }
}

.tribunus {
  .element {
    background-color: #8f37dc;
  }
}

.praetor {
  .element {
    background-color: #e78019;
  }
}

.praefectus {
  .element {
    background-color: #ce751c;
  }
}

.primusPilus {
  .element {
    background-color: #062eb4;
  }
}

.centurio {
  .element {
    background-color: #062eb4;
  }
}

.optio {
  .element {
    background-color: #062eb4;
  }
}

.legionarius {
  .element {
    background-color: #950e27;
  }
}

.tiro {
  .element {
    background-color: #30d5c8;
  }
}